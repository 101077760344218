import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./components/Layouts/Navbar";
import LeftSideBar from "./components/Layouts/LeftSideBar";
import ManageContentPage from "./components/cms/managecontentpage/ManageContentPage";
import ManageSlider from "./components/cms/manageSlider/ManageSlider";
import Footer from "./components/Layouts/Footer";
import AddContentPage from "./components/cms/managecontentpage/AddContentPage";
import AddSlider from "./components/cms/manageSlider/AddSlider";
import ManageSeoPage from "./components/cms/manageseopage/ManageSeoPage";
import ManageTestimonials from "./components/cms/managetestimonials/ManageTestimonials";
import AddSeoPage from "./components/cms/manageseopage/AddSeoPage";
import AddNewTestimonials from "./components/cms/managetestimonials/AddNewTestimonials";
import ManageAddBanner from "./components/cms/manageadsbanner/ManageAdsBanner";
import AddNewBanner from "./components/cms/manageadsbanner/AddNewBanner";
import ManagePricingPlans from "./components/pricingplans/pricingplans/ManagePricingPlans";
import AddPricingPlan from "./components/pricingplans/pricingplans/AddPricingPlan";
import EditContentPage from "./components/cms/managecontentpage/EditContentPage";
import EditSlider from "./components/cms/manageSlider/EditSlider";
import EditSeoPage from "./components/cms/manageseopage/EditSeoPage";
import EditTestimonials from "./components/cms/managetestimonials/EditTestimonials";
import EditAdsBanner from "./components/cms/manageadsbanner/EditAdsBanner";
import EditPricingPlan from "./components/pricingplans/pricingplans/EditPricingPlan";
import AddFaq from "./components/cms/faq/AddFaq";
import ManageFaqs from "./components/cms/faq/ManageFaqs";
import EditFaq from "./components/cms/faq/EditFaq";
import Login from "./components/Login/Login";
import ManagePage from "./components/cms/managepage/ManagePage";
import AddPage from "./components/cms/managepage/AddPage";
import EditPage from "./components/cms/managepage/EditPage";
import ManageLogoPage from "./components/cms/logo/ManageLogoPage";
import AddLogoPage from "./components/cms/logo/AddLogo";
import Dashboard from "./components/Dashboard";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFoundPage from "./components/cms/NotFoundPage";
import ManageSocialMedia from "./components/cms/mangesocialmedia/MangeSocialMedia";
import AddSocials from "./components/cms/mangesocialmedia/AddSocials";
import EditSocials from "./components/cms/mangesocialmedia/EditSocials";
import ManageIndustry from "./components/cms/managerIndustry/ManageIndustry";
import AddIndustry from "./components/cms/managerIndustry/AddIndustry";
import EditIndustry from "./components/cms/managerIndustry/EditIndustry";
import ManageEnquiry from "./components/cms/manageenquiry/manageenquiry";
import PositiveEnquiry from "./components/cms/manageenquiry/positiveenquiry";
import NegativeEnquiry from "./components/cms/manageenquiry/negativeenquiry";
import AddEnquiry from "./components/cms/manageenquiry/addenquiry";
import ManageSubscribe from "./components/cms/managesubscribe/managesubscribe";
import ManageCompanyProfile from "./components/cms/manageconpanyprofile/managecompanyprofile";
import AddCompanyProfile from "./components/cms/manageconpanyprofile/addcompanyprofile";
import ManageClient from "./components/cms/manageclient/manageclient";
import AddClient from "./components/cms/manageclient/addClient";
import CompanyInformation from "./components/cms/companyInformation/companyinformation";
import { isLoggedIn, selectUser } from "../src/components/store/auth/authSlice";
import { persistor } from "../src/components/store/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AddSubscription from "./components/cms/managePlans/manageSubscriptionPlan";
import EditPlan from "./components/cms/managePlans/editSubscriptionPlan";
import ManageUserData from "./components/cms/manageUserData/manageUserList";
import ViewUserDetail from "./components/cms/manageUserData/viewUserDetail";
import EditUser from "./components/cms/manageUserData/editUser";
import axios from "axios";
import { API_BASE_URL } from "./components/config";
import Manage_Trademark from "./components/cms/Manage-Trademark-Data/Manage_Trademark/Index";
import AddUser from "./components/cms/manageUserData/addUser";
import ManageSubscription from "./components/cms/managePlans/manageSubscriptionPlan";
import ManageGuestCustomer from "./components/cms/manageUserData/manageGuestList";
import Profile from "./components/Layouts/Profile";
import AddPlan from "./components/cms/managePlans/addSubscriptionPlan";
import CreateOrder from "./components/cms/manageUserData/createOrder";
import ManageOrderList from "./components/cms/manageUserData/manageOrderList";
import Manage_Search from "./components/cms/ManageSearch/Index";
import Detail from "./components/cms/Manage-Trademark-Data/Manage_Trademark/TrademarkDetail";
import ManageDocuments from "./components/cms/Manage-Trademark-Data/Manage_Documents";
import ManageApiHit from "./components/cms/ManageApi";
import ManageWalletHistory from "./components/cms/ManageWallet";
import ViewEnquiry from "./components/cms/manageenquiry/ViewEnquiry";

function App() {
  const user = useSelector(selectUser);
  const loggedIn = useSelector(isLoggedIn);

  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [islogin, setIslogin] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    websitelogo();
    setToken(localStorage.getItem("cms_token"));
    let path = window.location.pathname;
    console.log(path, "path");
    getDashboardData();
    if (token === null) {
      setIslogin(false);
      navigate("/cms/login");
    } else {
      navigate("/cms/home")
      setIslogin(true);
    }
  }, []);

  const websitelogo = async () => {
    try {
      const logo = await axios.get(API_BASE_URL + "/get-web-company-profile");
      const weblogo = JSON.parse(JSON.stringify(logo.data));
      if (weblogo.status == 200) {
        localStorage.setItem("logo_url", weblogo.data.logo);
        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = weblogo.data.favicon_icon;
        document.getElementsByTagName("head")[0].appendChild(link);
        document
          .querySelector('link[rel="icon"]')
          .setAttribute("href", weblogo.data.favicon_icon);
        document
          .querySelector('link[rel="canonical"]')
          .setAttribute("href", weblogo.data.website);
      }
    } catch (e) {
      return false;
    }
  };

  const getDashboardData = async () => {
    await axios
      .get(`${API_BASE_URL}/get-dashboard-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response, "res");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.status === 401) {
          localStorage.clear();
          if(token){
            setTimeout(() => {
              navigate("/cms/login");
              localStorage.clear();
              window.location.reload(); 
            }, [2000]);
          }
          else{
            navigate("/cms/login");
            localStorage.clear();
          }
          
         
        }
      });
  };
                                                                            
  return (
    <div>
      <>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />
          {!loggedIn ? (
            <>
              <Routes>
                <Route exact path="/cms/login" element={<Login />} />
              </Routes>
            </>
          ) : (
            <>
              <Navbar />
              <LeftSideBar />
              <Footer />
              <Routes>
                <Route path="/cms/home" element={<Dashboard />} />
                <Route path="/cms/managelogo" element={<ManageLogoPage />} />
                <Route
                  path="/cms/managelogo/addlogo"
                  element={<AddLogoPage />}
                />
                <Route path="/cms/managepage" element={<ManagePage />} /> 
                <Route
                  path="/cms/managepage/editpage/:id"
                  element={<EditPage />}
                />
                <Route path="/cms/managepage/addpage" element={<AddPage />} />
                <Route
                  path="/cms/managecontentpage"
                  element={<ManageContentPage />}
                />
                <Route
                  path="/cms/managecontentpage/addcontentpage"
                  element={<AddContentPage />}
                />
                <Route
                  path="/cms/managecontentpage/addcontentpage/:id"
                  element={<AddContentPage />}
                />
                <Route path="/cms/manageslider" element={<ManageSlider />} />
                <Route
                  path="/cms/manageslider/addnewslider"
                  element={<AddSlider/>}
                />
                <Route
                  path="/cms/manageslider/edit-slider/:id"
                  element={<AddSlider />}
                />
                <Route path="/cms/manageseopage" element={<ManageSeoPage />} />
                <Route
                  path="/cms/manageseopage/addseopage"
                  element={<AddSeoPage />}
                />
                <Route
                  path="/cms/manageseopage/editseopage/:id"
                  element={<EditSeoPage />}
                />
                <Route
                  path="/cms/managetestimonials"
                  element={<ManageTestimonials />}
                />
                <Route
                  path="/cms/managetestimonials/addnewtestimonials"
                  element={<AddNewTestimonials />}
                />
                <Route
                  path="/cms/managetestimonials/edittestimonials/:id"
                  element={<AddNewTestimonials />}
                />
                <Route
                  path="/cms/mangeadsbanner"
                  element={<ManageAddBanner />}
                />
                <Route
                  path="/cms/mangeadsbanner/addnewbanner"
                  element={<AddNewBanner />}
                />
                <Route
                  path="/cms/mangeadsbanner/editadsbanner/:id"
                  element={<EditAdsBanner />}
                />
                <Route
                  path="/cms/managepricingplans"
                  element={<ManagePricingPlans />}
                />
                <Route
                  path="/cms/managepricingplans/addpricingplans"
                  element={<AddPricingPlan />}
                />
                <Route
                  path="/cms/managepricingplans/editpricingplans"
                  element={<EditPricingPlan />}
                />
                <Route path="/cms/managefaq" element={<ManageFaqs />} />
                <Route path="/cms/managefaq/addfaq" element={<AddFaq />} />
                <Route
                  path="/cms/managefaq/editfaq/:id"
                  element={<EditFaq />}
                />
                <Route
                  path="/cms/managesocialmedia"
                  element={<ManageSocialMedia />}
                />
                <Route
                  path="/cms/managesocialmedia/addsocials"
                  element={<AddSocials />}
                />
                <Route
                  path="/cms/managesocialmedia/editsocials/:id"
                  element={<EditSocials />}
                />
                <Route
                  path="/cms/manageindustry"
                  element={<ManageIndustry />}
                />
                <Route
                  path="/cms/manageindustry/addindustry"
                  element={<AddIndustry />}
                />
                <Route
                  path="/cms/manageindustry/editindustry/:id"
                  element={<EditIndustry />}
                />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/cms/manageenquiry" element={<ManageEnquiry />} />

                <Route path="/cms/manageEnquiry/viewEnquiry/:id" element={<ViewEnquiry />} />

                <Route
                  path="/cms/manageenquiry/positive-enquiry"
                  element={<PositiveEnquiry />}
                />

                <Route
                  path="/cms/manageenquiry/negative-enquiry"
                  element={<NegativeEnquiry />}
                />

                <Route
                  path="/cms/manageenquiry/addenquiry"
                  element={<AddEnquiry />}
                />

                <Route
                  path="/cms/managesubscribe"
                  element={<ManageSubscribe />}
                />

                <Route
                  path="/cms/managecompanyprofile"
                  element={<ManageCompanyProfile />}
                />

                <Route
                  path="/cms/managecompanyprofile/add-company-profile"
                  element={<AddCompanyProfile />}
                />
                <Route
                  path="/cms/companyinformation"
                  element={<CompanyInformation />}
                />
                <Route
                  path="/cms/managecompanyprofile/add-company-profile/:id"
                  element={<AddCompanyProfile />}
                />

                <Route path="/cms/manageSponser" element={<ManageClient />} />

                <Route
                  path="/cms/manageSponser/add-sponser"
                  element={<AddClient />}
                />

                <Route
                  path="/cms/manageSponser/edit-sponser/:id"
                  element={<AddClient />}
                />
                <Route
                  path="/cms/manage-subscription-plan"
                  element={<ManageSubscription />}
                />
                {/* <Route
                  path="/cms/managePlan/add-subscription-plan"
                  element={<AddSubscription />}
                /> */}
                <Route
                  path="/cms/managePlan/add-subscription-plan"
                  element={<AddPlan />}
                />
                <Route
                  path="/cms/managePlan/edit-subscription-plan/:id"
                  element={<EditPlan />}
                />
                <Route
                  path="/cms/manage-customer"
                  element={<ManageUserData />}
                />
                <Route
                  path="/cms/manage-guest-customer"
                  element={<ManageGuestCustomer />}
                />
                <Route
                  path="/cms/manageUserData/UserData/viewUser/:id"
                  element={<ViewUserDetail />}
                />
                <Route
                  path="/cms/manageUserData/editUser/:id"
                  element={<EditUser />}
                />
                <Route
                  path="/cms/manageUserData/addUser"
                  element={<AddUser />}
                />
                <Route
                  path="/cms/manage-customer/manage-order"
                  element={<ManageOrderList />}
                />
                <Route
                  path="/cms/manage-customer/create-order"
                  element={<CreateOrder />}
                />
                <Route
                  path="/cms/manage-trademark-data/manage-trademark"
                  element={<Manage_Trademark />}
                />
                <Route
                  path="/cms/manage-trademark-data/view-trademark"
                  element={<Detail />}
                />
                <Route path="/cms/manage-search" element={<Manage_Search />} />

                <Route path="/cms/manage-document" element={<ManageDocuments />} />

                <Route path="/cms/manage-api" element={<ManageApiHit />} />

                <Route path="/cms/manage-wallet" element={<ManageWalletHistory />} />

                <Route path="/cms/profile" element={<Profile />} />
              </Routes>
            </>
          )}
        </PersistGate>
      </>
    </div>
  );
}

export default App;
