const local = "http://localhost:5000";
const live = window.location.origin;
const BASE_URL = window.location.origin

// const API_BASE_URL = live + "/api/v1";
// const API_URL = live;

const API_BASE_URL = "https://api.trademarksearch.app/public/api";
const API_URL = "https://api.trademarksearch.app";
const IMG_URl = "https://api.trademarksearch.app/";
export { API_BASE_URL, API_URL, IMG_URl, BASE_URL };



